@mixin NewShop {
  $font: 'Tajawal', serif;
  $headingFont: 'Tajawal', serif;
  $handWritingFont: 'Tajawal', serif;
  $titleColor: #212020;
  $subtitleColor: #212020;
  $backgroundColor: #fff;

  background: #{$backgroundColor} !important;

  .nav .nav-item .nav-link {
    padding: var(--bs-nav-link-padding-y) var(--bs-nav-link-padding-x) !important;
  }

  .nav-tabs .nav-link.active {
    color: black !important;
  }

  .nav .nav-item a {
    color: var(--bs-link-hover-color);
  }

  &:not(:has(.photographer-modal .modal)) {
    .modal {
      .modal-header {
        padding: 5px 10px;
      }

      .modal-header,
      .modal-content {
        background: #{$backgroundColor} !important;
        color: #000 !important;
      }

      .modal-content {
        border: none;
        border-radius: 0;

        .modal-header button {
          border: 0;
          margin-left: auto;
          font-size: 20px;
          background-color: #000 !important;
        }

        .modal-body {
          p,
          label,
          svg {
            color: #000 !important;
          }

          input,
          .input-element {
            border: 1px solid #000;
            background-color: #fff;
            border-radius: 10px;
            color: #000 !important;
          }

          .sb-btn {
            background-color: #000 !important;
            color: #fff !important;
            border-radius: 30px;

            svg {
              color: #fff !important;
              fill: #fff !important;
              stroke: #fff !important;
            }
          }
        }
      }
    }
  }

  .profile-dropdown-toggle {
    &:before {
      content: '\f007' !important;
      font: normal normal normal 25px / 1 FontAwesome !important;
    }
  }
}
