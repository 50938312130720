// Here you can add other styles

body {
  > iframe {
    display: none;
  }
  overflow-x: hidden;
  position: relative;
}

#app:not(.site-editor-frame) {
  min-height: 100vh;
}

.toast-custom-top-position {
  top: 50px;
}

.Player__controls-wrapper {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  .Controls {
    background: #eaeaea !important;
    border: 0px solid #000 !important;
    height: 40px;
    padding-left: 15px;
    color: black !important;
  }

  .Controls__btn {
    width: 15px;
  }
  .Controls__fullscreen-btn {
    width: 15px;
  }
}

.progress-fade-out {
  -webkit-transition: background 5s;
}

.progress-text-center {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  color: black;
}

.reactable-header-sortable {
  cursor: pointer;
}

.position-unset {
  position: unset !important;
}

.reactable-th-hiddentitle {
  display: none;
}

.reactable-data {
  td[label='hiddenTitle'] {
    display: none;
  }
}

.line-height-1 {
  line-height: 1 !important;
}

.comment-badge {
  width: 13px;
  height: 13px;
  position: absolute;
  background-color: red;
  border-radius: 50%;
}

.tooltip span.arrow {
  display: none !important;
}

.p-b-125 {
  padding-bottom: 125px !important;
}

.m-t-125 {
  margin-top: 125px !important;
}

.m-t-150 {
  margin-top: 150px !important;
}

.p-b-150 {
  padding-bottom: 150px !important;
}

.photoswipe-custom-button-comment .comment-badge {
  margin-left: -10px;
  margin-top: -3px;
}

.aside-menu-lg-show {
  .help-floating-wrapper {
    right: 270px !important;
  }
}

.custom-dropdown-position {
  .dropdown-menu.dropdown-menu-right {
    right: 10px !important;
  }
}

.sorting-nav-item {
  list-style: none;
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
  background: #fff;
  border-color: #c8ced3;
  border-bottom-color: #fff;
}

.sortable-nav-container {
  white-space: nowrap;
  scroll-behavior: smooth;
  overflow-x: auto;
  overflow-y: hidden;
  margin-right: 35px;

  .sortable.nav-tabs {
    flex-wrap: nowrap !important;
  }

  .gallery-tabs-overflow {
    right: 15px;
    top: 5px;
    padding: 0 15px;
    font-size: 20px;
    position: absolute;
    cursor: pointer;
    z-index: 99;
  }
}

.img-avatar {
  height: 35px;
  margin: 0 10px;
}

.top-fixed-progess {
  position: fixed;
  width: 100%;
  left: 0;
  z-index: 9999;
}

.table-responsive {
  min-height: 300px;
}

.cursor-not-allowed {
  cursor: not-allowed;
}

.m-t--2 {
  margin-top: -2px;
}

.cursor-help {
  cursor: help;
}

.is-draggable {
  cursor: move !important;
}

.no-select {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome and Opera */
}

.no-drag {
  -webkit-user-drag: none; /* Prevents dragging of images/divs etc */
  user-drag: none;
}

.h-600 {
  height: 600px;
}

.max-w-1500 {
  max-width: 1500px;
}

.color-danger {
  color: #f86c6b;
}

.collection-design {
  img {
    border-radius: 10px;
    border: solid 5px;
    border-color: grey;
  }

  img.selected {
    border-color: #066cd2;
  }

  i {
    display: none;
  }

  i.fa-check.selected {
    display: block;
    padding: 10px;
    background-color: #066cd2;
    color: white;
    border-radius: 50%;
    font-size: 20px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  i.fa-lock {
    display: block;
    padding-top: 10px;
    padding-bottom: 10px;
    background-color: grey;
    color: white;
    border-radius: 50%;
    font-size: 20px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 25px;
    padding-right: 15px;
    padding-left: 15px;
  }
}

.sticky-bottom {
  bottom: 0;
  position: fixed;

  button {
    width: 100%;
  }
}

.GotPhoto {
  $gotPhotoBlue: #4d5fff;

  .btn-xl {
    font-size: 1.5rem !important;
  }

  .special-offer-features {
    list-style-type: none;
    padding-left: 20px;

    li:before {
      font-family: 'FontAwesome';
      content: '\f00c';
      margin: 0 5px 0 -20px;
      color: green;
    }
  }

  .product-options {
    list-style-type: none;
    white-space: nowrap;
    overflow-x: auto;
    padding: 10px 5px;
    margin: 10px -10px 25px;

    li {
      width: 33.33333%;
      padding: 0 5px;
      float: left;

      .btn-option {
        width: 100%;
        border: 1px solid #aaa;
        margin: 0;
        background: 0 0;
        height: 40px;
        line-height: 40px;
        font-size: 12px;
        white-space: normal;
        overflow: visible;
        padding: 0;
        box-sizing: border-box;
        text-align: center;

        &.active {
          color: $gotPhotoBlue;
          border-color: $gotPhotoBlue;
        }
      }
    }
  }

  .hidden-upload {
    width: 100%;
    height: auto;
    left: 0;
  }

  .image_picker .responsive {
    margin: 0 6px 6px !important;
  }

  .image_picker .responsive .thumbnail {
    width: 100px !important;
    height: 100px !important;
  }

  .image_picker .selected .checked .icon {
    height: 25px !important;
    width: 25px !important;
  }

  .sticky-bottom {
    bottom: 0;
    position: fixed;

    button {
      width: 100%;
    }
  }

  h1 {
    font-weight: 600;
  }

  h3 {
    font-weight: 600 !important;
  }

  .create-photobook-video-wrapper {
    .d-450-down {
      display: inline-block;
    }

    .d-450-up {
      display: none;
    }

    @media screen and (min-width: 450px) {
      .d-450-up {
        display: inline-block;
      }
      .d-450-down {
        display: none;
      }
    }

    .create-photobook-video {
      left: 22%;
      top: 0px;
      width: 56%;
      position: absolute;
      max-width: 240px;

      video {
        left: 8%;
        padding-top: 6.8%;
        width: 80%;
        object-fit: cover;
        position: absolute;
      }

      .ios-video-frame {
        height: 60%;
        left: 2%;
        position: absolute;
        top: 1%;
        width: 93%;
      }

      @media screen and (min-width: 450px) {
        left: 50%;
        top: 13%;
        width: 41%;
        max-width: 615px;

        video {
          left: 4.9%;
          padding-top: 3.4%;
          width: 34.3%;
        }

        .ios-video-frame {
          width: 40%;
        }
      }
    }
  }

  .site-hero-header {
    height: 100vh;

    .site-hero-header-title {
      margin-bottom: 1.5rem;
      margin-top: 0;
    }

    .site-hero-header-content {
      top: unset;
      transform: unset;
      margin-top: 0;
    }

    .trans-black-overlay {
      background-color: rgba(25, 25, 25, 0.8);
    }

    .hero-cta {
      color: white;

      .icon {
        font-size: 2.5rem;
      }
    }

    @media screen and (min-width: 768px) {
      .site-hero-header-title {
        margin-bottom: 100px;
      }

      .site-hero-header-content {
        margin-top: 40px;
      }
    }
  }

  .hero-scroll {
    padding: 3.5vh;
  }

  .btn-gotPhoto {
    color: white;
    background-color: #4d5fff;
  }

  .blue-color {
    color: $gotPhotoBlue;
  }

  .slick-slide {
    height: 110px;
  }

  .slick-prev:before,
  .slick-next:before {
    color: $gotPhotoBlue;
  }

  .blue-bg-color {
    background-color: #4d5fff;
  }

  .share-menu {
    a {
      width: 50px !important;
      height: 50px !important;
      padding: 16px !important;
      margin-top: 20px;
      margin-left: 10px;
      margin-right: 10px;
      transition: all 0.2s ease-in-out;
      border-radius: 50%;

      i {
        color: white;
        font-size: 20px;
        margin-top: -3px;
        margin-left: -2px;
      }
    }

    .whatsapp {
      padding: 12px !important;
    }

    @media screen and (min-width: 576px) {
      a {
        margin-left: 20px;
        margin-right: 20px;
      }
    }
  }
}

.watermark-positions-table {
  height: 100px;
  width: 100px;

  td {
    border: 1px solid #c8ced3;
    transition: all 175ms ease-out;
    cursor: pointer;
  }

  td.active {
    background-color: $primary;
  }

  td.not-allowed {
    background-color: #c8ced3;
    cursor: not-allowed;
  }
}

.border-left {
  border-left: 1px solid #c8ced3;
}

.rc-slider-track {
  background-color: $primary !important;
}

.rc-slider-handle {
  border: solid 2px $primary !important;
}

.rc-slider-handle:active {
  border-color: $primary !important;
  box-shadow: 0 0 5px $primary !important;
}

.m-t-1 {
  margin-top: 1px !important;
}

.watermark-preview-card {
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
}

.masonry {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: stretch;
  width: 100%;
  margin: auto;
}

.column {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-content: stretch;
  flex-grow: 1;
}

.resp-gallery-sizer,
.resp-gallery-image {
  width: 49.2%;
}

.resp-gallery-gutter-sizer {
  width: 0.8%;
}

.resp-gallery-image {
  margin-bottom: 0.8%;
}

.resp-gallery {
  margin-left: 0.8%;
  margin-top: 0.8%;
}

@media screen and (min-width: 961px) {
  .resp-gallery-sizer,
  .resp-gallery-image {
    width: 32.3%;
  }
  .resp-gallery-gutter-sizer {
    width: 1%;
  }
  .resp-gallery-image {
    margin-bottom: 1%;
  }
  .resp-gallery {
    margin-left: 1%;
    margin-top: 1%;
  }
}

@media screen and (min-width: 1440px) {
  .resp-gallery-sizer,
  .resp-gallery-image {
    width: 24%;
  }
}

.ScrAppBook {
  .photoswipe-custom-button {
    width: 44px;
    height: 44px;
    position: relative;
    background: none;
    cursor: pointer;
    overflow: visible;
    -webkit-appearance: none;
    display: block;
    border: 0;
    padding: 0;
    margin: 0;
    float: right;
    opacity: 0.75;
    -webkit-transition: opacity 0.2s;
    -o-transition: opacity 0.2s;
    transition: opacity 0.2s;
    -webkit-box-shadow: none;
    box-shadow: none;
    color: #e3e3e3;
    font-size: 23px;

    svg {
      color: gray;
    }
  }

  .photoswipe-custom-button:hover {
    opacity: 1;
  }

  .photoswipe-like-button-liked,
  .photoswipe-like-button-liked .photoswipe-custom-button-like {
    color: red !important;
  }

  .pswp__top-bar {
    opacity: 1 !important;
  }

  .badge-comment {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -16px;
    margin-left: 0;
    border-radius: 10rem;
    font-size: 50% !important;
  }

  .nav {
    .nav-item {
      a {
        color: black;
      }

      i {
        font-size: 1.75em;
      }

      .nav-link {
        padding: 0.25rem !important;
      }
    }
  }

  .container-fluid {
    margin-left: -15px !important;
    margin-right: -15px !important;
  }
}

.tab-add-gallery {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.custom-col-padding {
  padding-left: 5px !important;
  padding-right: 5px !important;
  margin-bottom: 5px;
}

.download-pin-input {
  padding: 10px;
  border: 1px solid #ddd;
  width: 50px;
  height: 50px;
  text-align: center;
  font-size: 30px;
}

.selections {
  .selection-image-icon-wrapper {
    position: absolute;
    background-color: white;
    padding: 0 2px 2px 5px;
    z-index: 10;
  }

  .selection-preview-wrapper {
    overflow: auto;
    white-space: nowrap;
    scroll-behavior: smooth;

    .selection-preview-button {
      height: 275px;
      width: 250px;
      display: inline-table;
      padding: 15px;
      opacity: 0.5;
      white-space: normal;
      border: 1px solid #e4e5e6;

      .preview-image {
        margin: 15px auto 15px auto;
        height: 75px;
        width: 75px;
        border-radius: 50%;
        background-position: center;
      }

      .endCustomer-message {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        width: 200px;
      }
    }

    .selection-preview-button.selected {
      opacity: 1;
      background-color: white;
    }
  }
}

.gallery-images-wrapper {
  .gallery-image {
    margin-bottom: 10px !important;

    .card {
      margin-bottom: 0;
    }
  }

  .gallery-image-card {
    margin-bottom: 0 !important;

    .card-header {
      .text {
        display: inline-block;
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        cursor: default;
        margin-top: 4px;
        margin-left: 4px;
      }

      .icon {
        margin-top: 8px;
      }

      .icon.icon-comment {
        margin-left: 7px;
        font-size: 15px;
      }
    }

    .spinner {
      margin-top: 85px;
    }

    .card-header,
    .card-footer {
      padding: 2px !important;
    }

    .card-body {
      padding: 0 !important;
      margin: 10px !important;

      img.img-fluid {
        max-height: 180px;
      }
    }
  }

  .uploading-gallery-placeholder {
    border: 2px dashed #56585a;
    padding: 10px;
    border-radius: 0.25rem;

    .placeholder-image {
      height: 50px;
      margin-bottom: 10px;
    }
  }
}

.app-icon-preview {
  width: 75px;
  height: 75px;
  border-radius: 10px;
}

.color-primary {
  color: $primary;
}

.collection-swicht-wrapper {
  padding: 0.375rem;
  margin-bottom: 10px;
}

.collection-icon-container {
  display: inline-block;
  width: 150px;
}

.collection-meta-settings-container {
  display: inline-block;
  width: calc(100% - 150px);
}

.sidebar-minimized.sidebar-lg-show {
  .collection-edit-button-bar {
    width: 100%;
    left: 0;
  }

  @media (min-width: 992px) {
    .collection-edit-button-bar {
      position: absolute;
      width: calc(100% - 50px);
      transition: width 0.25s;
      margin: 0;
      left: 50px;
    }
  }
}

.aside-menu .custom-aside-toggler.navbar-toggler {
  background-color: $primary !important;
}

.collection-edit-button-bar {
  .headline {
    color: white;
    margin-left: 33px;
    margin-bottom: 0;
    display: inline;
  }

  .custom-aside-toggler.navbar-toggler {
    line-height: inherit;
    border-radius: 0;
  }

  @media (min-width: 992px) {
    .custom-aside-toggler,
    .settings-toggler {
      display: none;
    }
  }

  @media (max-width: 768px) {
    .button {
      span {
        display: none;
      }
    }
  }
}

.sidebar-lg-show.aside-menu-lg-show {
  .collection-edit-button-bar {
    left: 0;
    width: calc(100% - 250px);
  }

  @media (min-width: 992px) {
    .collection-edit-button-bar {
      position: absolute;
      width: calc(100% - 300px);
      transition: width 0.25s;
      margin: 0;
      left: 50px;
    }
  }
}

.aside-menu-lg-show {
  .collection-edit-button-bar {
    left: 0;
    width: calc(100% - 250px);
  }
}

.aside-menu-lg-show .aside-menu {
  margin-right: 0px !important;
}

.sidebar-lg-show .collection-edit-button-bar {
  position: absolute;
  width: calc(100% - 200px);
  transition: width 0.25s;
  margin: 0;
  left: 200px;
}

.collection-edit-button-bar {
  left: 0;
  position: absolute;
  top: 55px;
  height: 45px;
  width: 100%;
  transition: width 0.25s;
  margin: 0;
  background-color: #2f353a;

  .divider {
    width: 1px;
    height: 100%;
    background-color: #8d9396;
    float: right;
  }

  .button {
    background-color: transparent;
    color: white;
    font-size: 15px;
    padding: 11px;
    float: right;
    border: 0;
  }

  .button:hover {
    background-color: #53575a;
  }
}

.edit-collection-container {
  height: calc(100vh - 350px);
  margin-top: 65px;
}

.drag-target {
  background-color: #066cd266;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.uploading-gallery-image-wrapper {
  display: inline-block;
  margin: 0.25rem;
  width: 200px;

  .dropdown-item {
    border-bottom: 0;
  }

  .spinner-wrapper {
    overflow: hidden;
    height: 200px;
    text-align: center;
  }

  .uploading-gallery-image-footer,
  .uploading-gallery-image-title {
    background-color: #f0f3f5;
    padding: 5px;

    .dropdown-item i {
      color: unset !important;
    }

    .text {
      display: inline-block;
      width: 80%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      cursor: default;
    }

    .icon {
      margin-top: 3px;
      margin-right: 3px;
      float: right;
      cursor: pointer;
    }

    .dropdown-menu.show {
      transform: translate3d(15px, 22px, 0px) !important;
    }
  }

  .uploading-gallery-image-title {
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .uploading-gallery-image {
    background-size: cover;
    background-position: center;
    height: 200px;
    overflow: hidden;
  }
}

.h-100-p {
  height: 100% !important;
}

.cp-gallery-image,
.site-gallery-image {
  cursor: pointer;
}

.btn.btn-icon .fa {
  margin-right: 5px;
}

.m-b-minus-7 {
  margin-bottom: -7px;
}

.discount-alert-margins {
  margin-top: -1.5rem;
  margin-bottom: 1.5rem;
}

.edit-site-title {
  background-color: transparent !important;
  border-color: transparent !important;
  color: white !important;
  font-weight: 800 !important;
  font-size: 1.7rem !important;
  word-wrap: break-word !important;
  text-align: center;
  box-shadow: 0 0 0 3px rgb(6, 108, 210) !important;
  background-color: rgba(193, 224, 251, 0.2);
  outline-style: none;
  height: auto;
  padding: 0;
}

.line-through {
  text-decoration: line-through;
}

.account-setup-checkbox .switch-pill .switch-slider {
  margin-top: 7px;
}

//vom ehemaligem main.CSS
.App-logo {
  height: 200px;
}

.absolute {
  position: absolute;
}

.t-200 {
  top: 200px !important;
}

.new-site {
  font-size: 1.2rem;
}

.max-w-1024 {
  max-width: 1024px;
}

.w-100-p {
  width: 100%;
}

.max-w-100-p {
  max-width: 100%;
}

.w-50-p {
  width: 50%;
}

.m-r-22 {
  margin-right: 22px !important;
}

.inline {
  display: inline;
}

.btn.btn-primary.btn-outline {
  color: white !important;
}

.site-editor-elements {
  width: 400px;
  border-right: 0;
  display: block;
  height: 100%;
  top: 0;
  left: 0;
  position: fixed;
  color: white;
  overflow-y: auto;
}

.site-editor-frame {
  margin-bottom: 10px;
  box-shadow: 0 0 60px #18191f;
  display: block;
}

@media (min-width: 992px) {
  .site-editor-frame {
    height: calc(100vh - 195px);
    padding: 0;
    overflow-y: auto;
    overflow-x: hidden;
  }

  .site-editor-frame-discount {
    height: calc(100vh - 260px) !important;
  }
}

.site-editor-frame .share {
  bottom: 35px !important;
  right: 40px !important;
}

.site-editor-footer {
  background-color: #3a4248;
  margin-bottom: 10px;
}

.loading {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: #fff;
  z-index: 10;
}

.loader {
  left: 50%;
  margin-left: -4em;
  font-size: 10px;
  border: 0.8em solid rgba(218, 219, 223, 1);
  border-left: 0.8em solid #000000;
  animation: spin 1.1s infinite linear;
}

.loader,
.loader:after {
  border-radius: 50%;
  width: 8em;
  height: 8em;
  display: block;
  position: absolute;
  top: 50%;
  margin-top: -4.05em;
}

@keyframes spin {
  0% {
    transform: rotate(360deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

.modal-fullscreen {
  width: 100% !important;
  height: 100% !important;
  margin: 0 !important;
  padding: 0 !important;
  max-width: 100% !important;
}

.modal-fullscreen > .modal-content {
  height: auto;
  min-height: 100%;
  border-radius: 0;
  width: 100%;
}

.modal-fullscreen > .modal-content > .modal-body {
  height: auto !important;
  max-height: calc(100vh - 130px) !important;
  overflow: hidden;
  overflow-y: hidden;
  overflow-y: auto;
}

.btn-xl {
  padding: 0.5rem 1rem !important;
  font-size: 1.25rem !important;
  line-height: 1.5 !important;
}

.btn-lg {
  padding: 0.5rem 1rem !important;
  font-size: 1.1rem !important;
  line-height: 1.3 !important;
}

.image-container {
  position: relative;
  display: inline-block;
  height: 150px;

  .alert {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
    padding: 0.25rem;
    font-size: 11px;
  }

  img {
    max-height: 100%;

    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
  }
}

.button-over-image {
  position: absolute;
  width: 100%;
  top: 80px;
  opacity: 0;
  transition: opacity 0.35s ease;
}

.image-container:hover .button-over-image {
  opacity: 1;
}

.site-editor-elements-sections .collapse-parent .collapse-parent {
  border: 1.5px dotted grey;
  border-top: 0;
}

.site-editor-elements-sections .collapse-parent {
  background: #2f353a;
}

.site-editor-elements-sections .collapse-parent hr {
  border-top: 1.5px dotted grey;
}

.site-editor-elements-sections .btn-outline {
  border-width: 2px;
}

.site-editor-elements-sections,
.site-editor-elements-header {
  color: white;
}

.site-editor-elements-header .site-editor-share {
  background-color: #2f353a;
}

.site-editor-elements-sections button,
.site-editor-section-btn {
  border-radius: 0;
}

.site-editor-elements-wrapper {
  background-color: #3a4248;
}

.border-0 {
  border: 0;
  border-radius: 0;
}

@media (min-width: 992px) {
  .site-editor-elements-wrapper {
    overflow-y: auto;
    position: absolute;
    height: calc(100vh - 195px);
    margin-bottom: 10px;
  }
}

.sli-xs {
  font-size: 12px;
}

.cursor-default {
  cursor: default !important;
}

.cursor-pointer {
  cursor: pointer !important;
}

.site-editor-elements-sections .control {
  cursor: pointer;
}

.site-editor-elements-sections .control-area {
  float: right;
}

hr {
  margin-top: 10px !important;
  margin-bottom: 10px !important;
}

.progress .progress-bar {
  height: unset !important;
}

.btn {
  white-space: normal !important;
}

.loading-indicator {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: #fff;
}

.loader-indicator {
  left: 50%;
  margin-left: -4em;
  font-size: 10px;
  border: 0.8em solid rgba(218, 219, 223, 1);
  border-left: 0.8em solid #000000;
  animation: spin 1.1s infinite linear;
}

.loader-indicator,
.loader-indicator :after {
  border-radius: 50%;
  width: 8em;
  height: 8em;
  display: block;
  position: absolute;
  top: 50%;
  margin-top: -4.05em;
}

@keyframes spin {
  0% {
    transform: rotate(360deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

.btn:disabled {
  cursor: not-allowed !important;
}

/*.navbar-nav .active {
  border-bottom: 2px solid map-get($bootstrap-colors, primary);
}*/

.classic-border {
  border: 1px solid #e0e0e0;
}

.progress {
  background-color: #eeeeee !important;
}

.form-control:disabled {
  color: grey !important;
}

.reactable-th-description {
  max-width: 450px;
  width: 450px;
}

.custom-card-color {
  color: #394959 !important;
  background-color: white !important;
  min-height: 330px;
}

.card-block footer {
  position: absolute;
  bottom: 0;
  width: 100%;
}

.active-primary-button {
  background-color: #1563b0 !important;
  background-image: none !important;
  border-color: #145ca4 !important;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5) !important;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5) !important;
}

.no-float {
  float: unset !important;
}

//Common
.cursor-pointer {
  cursor: pointer !important;
}

.classic-border {
  border: 1px solid #e0e0e0;
}

.w-100-p {
  width: 100% !important;
}

//Common End

.reactable-pagination a {
  margin-right: 5px;
}

.rbc-calendar {
  flex: 1;
  min-height: 580px;
}

.rbc-calendar a {
  color: #394959 !important;
}

.rbc-timeslot-group .rbc-time-slot:hover {
  cursor: pointer;
  background-color: #066cd2 !important;
}

.rbc-toolbar {
  display: block !important;
}

.choose-template-card i {
  font-size: 50px;
}

.choose-template-card.card {
  margin-bottom: 0;
  height: 100%;
}

.custom-checkbox [type='checkbox'] {
  z-index: 2;
  opacity: 1;
}

.template-preview {
  .videowrap {
    margin-left: auto;
    margin-right: auto;
    width: 230px;
    position: relative;

    video {
      max-width: 89%;
      position: absolute;
      top: 2%;
      left: 5.5%;
    }

    .videoframe {
      position: relative;
    }
  }
}

.btn-github {
  color: #fff;
  background-color: #4183c4;
  border-color: #4183c4;
}

.btn-github:hover {
  color: #fff;
  background-color: #3570aa;
  border-color: #3269a0;
}

.btn-github:focus,
.btn-github.focus {
  box-shadow: 0 0 0 0.2rem rgba(94, 150, 205, 0.5);
}

.btn-github.disabled,
.btn-github:disabled {
  color: #fff;
  background-color: #4183c4;
  border-color: #4183c4;
}

.btn-github:not(:disabled):not(.disabled):active,
.btn-github:not(:disabled):not(.disabled).active,
.show > .btn-github.dropdown-toggle {
  color: #fff;
  background-color: #3269a0;
  border-color: #2f6397;
}

.btn-github:not(:disabled):not(.disabled):active:focus,
.btn-github:not(:disabled):not(.disabled).active:focus,
.show > .btn-github.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(94, 150, 205, 0.5);
}

.btn-facebook {
  color: #fff;
  background-color: #3b5998;
  border-color: #3b5998;
}

.btn-facebook:hover {
  color: #fff;
  background-color: #30497c;
  border-color: #2d4373;
}

.btn-facebook:focus,
.btn-facebook.focus {
  box-shadow: 0 0 0 0.2rem rgba(88, 114, 167, 0.5);
}

.btn-facebook.disabled,
.btn-facebook:disabled {
  color: #fff;
  background-color: #3b5998;
  border-color: #3b5998;
}

.btn-facebook:not(:disabled):not(.disabled):active,
.btn-facebook:not(:disabled):not(.disabled).active,
.show > .btn-facebook.dropdown-toggle {
  color: #fff;
  background-color: #2d4373;
  border-color: #293e6a;
}

.btn-facebook:not(:disabled):not(.disabled):active:focus,
.btn-facebook:not(:disabled):not(.disabled).active:focus,
.show > .btn-facebook.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(88, 114, 167, 0.5);
}

.btn-twitter {
  color: #fff;
  background-color: #00aced;
  border-color: #00aced;
}

.btn-twitter:hover {
  color: #fff;
  background-color: #0090c7;
  border-color: #0087ba;
}

.btn-twitter:focus,
.btn-twitter.focus {
  box-shadow: 0 0 0 0.2rem rgba(38, 184, 240, 0.5);
}

.btn-twitter.disabled,
.btn-twitter:disabled {
  color: #fff;
  background-color: #00aced;
  border-color: #00aced;
}

.btn-twitter:not(:disabled):not(.disabled):active,
.btn-twitter:not(:disabled):not(.disabled).active,
.show > .btn-twitter.dropdown-toggle {
  color: #fff;
  background-color: #0087ba;
  border-color: #007ead;
}

.btn-twitter:not(:disabled):not(.disabled):active:focus,
.btn-twitter:not(:disabled):not(.disabled).active:focus,
.show > .btn-twitter.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(38, 184, 240, 0.5);
}

.btn-pinterest {
  color: #fff;
  background-color: #cb2027;
  border-color: #cb2027;
}

.btn-pinterest:hover {
  color: #fff;
  background-color: #aa1b21;
  border-color: #9f191f;
}

.btn-pinterest:focus,
.btn-pinterest.focus {
  box-shadow: 0 0 0 0.2rem rgba(211, 65, 71, 0.5);
}

.btn-pinterest.disabled,
.btn-pinterest:disabled {
  color: #fff;
  background-color: #cb2027;
  border-color: #cb2027;
}

.btn-pinterest:not(:disabled):not(.disabled):active,
.btn-pinterest:not(:disabled):not(.disabled).active,
.show > .btn-pinterest.dropdown-toggle {
  color: #fff;
  background-color: #9f191f;
  border-color: #94171c;
}

.btn-pinterest:not(:disabled):not(.disabled):active:focus,
.btn-pinterest:not(:disabled):not(.disabled).active:focus,
.show > .btn-pinterest.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(211, 65, 71, 0.5);
}

/*
.strategy-modal-body .form-check-input {
  transform: scale(2);
}*/
.full-screen {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

// SliseshowPlayer

.Player__wrapper {
  @media screen and (min-width: 1200px) {
    height: 30vw !important;
  }
}

// Cropper

.cropper-point {
  background-color: red !important;
}

// Photoswipe modal

.pswp {
  .pswp__counter,
  .pswp__button--zoom,
  .pswp__button--close {
    display: none !important;
  }

  .pswp__img {
    cursor: auto;
  }

  .pswp__scroll-wrap {
    .pswp__button {
      transition: all 0.3s ease-out;
      &:hover {
        svg {
          fill: #b2b2b2;
        }
      }
      // background: none !important;
    }
    .pswp__button--arrow {
      background-repeat: no-repeat !important;
      background-size: 264px 88px;
      width: 44px;
      height: 44px;

      @media all and (max-width: 770px) {
        display: none !important;
      }
    }
  }
}

.custom-fadeIn {
  visibility: visible !important;
  transition: visibility 0.5s linear;
  animation: fadeIn 0.5s linear;

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
}

.custom-fadeOut {
  visibility: hidden !important;
  transition: visibility 0.5s linear;
  animation: fadeOut 0.5s linear;

  @keyframes fadeOut {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }
}

.Toastify__toast-container {
  z-index: 999999 !important;
}

@container banner-container (min-width: 100px) {
  .banner-row-2,
  .banner-modal-discount {
    font-size: 30px !important;
  }
}

@container banner-container (min-width: 200px) {
  .banner-row-2,
  .banner-modal-discount {
    font-size: 42px !important;
  }
}

@container banner-container (min-width: 280px) {
  .banner-row-2,
  .banner-modal-discount {
    font-size: 60px !important;
  }
}

@container banner-container (min-width: 380px) {
  .banner-row-2,
  .banner-modal-discount {
    font-size: 80px !important;
  }
}
