//padding from 0 to 100px in 5px increments
@for $i from 0 through 20 {
  .p-#{$i * 5} {
    padding: #{$i * 5}px !important;
  }
}
//padding-left from 0 to 100px in 5px increments
@for $i from 0 through 20 {
  .p-l-#{$i * 5} {
    padding-left: #{$i * 5}px !important;
  }
}
//padding-right from 0 to 100px in 5px increments
@for $i from 0 through 20 {
  .p-r-#{$i * 5} {
    padding-right: #{$i * 5}px !important;
  }
}
//padding-top from 0 to 100px in 5px increments
@for $i from 0 through 20 {
  .p-t-#{$i * 5} {
    padding-top: #{$i * 5}px !important;
  }
}
//padding-bottom from 0 to 100px in 5px increments
@for $i from 0 through 20 {
  .p-b-#{$i * 5} {
    padding-bottom: #{$i * 5}px !important;
  }
}
