//height and min-height from 0 to 100px
@for $i from 0 through 10 {
  .h-#{$i * 10} {
    height: #{$i * 10}px !important;
  }
  .min-h-#{$i * 10} {
    min-height: #{$i * 10}px !important;
  }
  .max-h-#{$i * 10} {
    max-height: #{$i * 10}px !important;
  }
}
//height and min-height from 150px to 500px in 50px increments
@for $i from 3 through 10 {
  .h-#{$i * 50} {
    height: #{$i * 50}px !important;
  }
  .min-h-#{$i * 50} {
    min-height: #{$i * 50}px !important;
  }
  .max-h-#{$i * 50} {
    max-height: #{$i * 50}px !important;
  }
}
